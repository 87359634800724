import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ContactFormCont } from "../containers"
import location from "../images/location.svg"
import "../styles/footer.css"

const legalesLinks = [
  {
    to:
      "https://drive.google.com/file/d/1XECH3kR3Dlk2Px3kRdsYQIew51DX1VkN/view",
    name: "Política de venta",
  },
  {
    to:
      "https://drive.google.com/file/d/1oXVFCb1shjiWOjfTWrq3-3mzG4ewwGwp/view",
    name: "  Política de garantías y devoluciones",
  },
  {
    to:
      "https://drive.google.com/file/d/1B4HhDMqIcVb3mjbeatHB3lTjhg2kgmzB/view",
    name: "Aviso de privacidad",
  },
]

const sucursales = [
  {
    imgC: location,
    title: "Alfa Ferre Sidar",
    text: "Pablo Sidar sur 118, San Pablo del Monte, CP. 90970",
    tel: "Tel. 222 282 3007",
    cel: "Cel.222 831 9465",
    to: "https://g.page/alfa-ferre-sidar?share",
  },
  {
    imgC: location,
    title: "Alfa Ferre Centro",
    text: "Ayuntamiento Ote. 13-C, San Pablo del Monte, CP. 90970",
    tel: "Tel. 222 282 1104",
    cel: "Cel.222 831 9465",
    to: "https://goo.gl/maps/bRG5pXJU3BGEfinr7 ",
  },
]

function Footer() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }
  return (
    <>
      <Container className="bg-black" fluid>
        <Row className="px-md-5  py-2 align-items-center">
          <Col sm={12} md={8} className="pl-md-0">
            <h3 className=" text-light sizeTitle text-md-left text-center">
              Consulta nuestras{" "}
              <span className="color_secondary_text">promociones en redes</span>
            </h3>
          </Col>
          <Col
            sm={12}
            md={4}
            className="d-flex flex-row justify-content-center pr-0"
          >
            <a
              className="mr-md-5 mr-2 "
              target="_blank"
              rel="noopener noreferrer"
              href=" https://www.facebook.com/Alfaferre "
            >
              <FontAwesomeIcon
                size="3x"
                className="text-light sizeicon"
                icon={["fab", "facebook-square"]}
              />
            </a>
            <a
              className="mr-md-5 mr-2 "
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/alfaferre/ "
            >
              <FontAwesomeIcon
                size="3x"
                className="text-light sizeicon"
                icon={["fab", "instagram-square"]}
              />
            </a>
            <a
              className="mr-md-3 "
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCzCkM8sEa1VKiY-x0gkAOiw/playlists?view_as=subscriber"
            >
              <FontAwesomeIcon
                size="3x"
                className="text-light sizeicon"
                icon={["fab", "youtube-square"]}
              />
            </a>
          </Col>
        </Row>
      </Container>
      <Container className="bg-secondary p-md-5" tag="footer" fluid>
        <Row className="mb-3">
          <Col>
            <h2 className="pr-1 pl-0 text-light text-md-left text-center sizeTitle">
              Regístrate, selecciona el servicio en el que estás interesado y
              nos pondremos en contacto contigo
            </h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={5}>
            <Row>
              <Col className="py-2" xs={12} md={6}>
                <Row className="pl-3 pl-md-0">
                  <Col xs={2} md={3}>
                    <FontAwesomeIcon
                      size="3x"
                      className="text-light rounded-circle bg-black p-2 icon_cont "
                      icon={["fas", "phone-volume"]}
                    />
                  </Col>
                  <Col className="pr-md-0">
                    {" "}
                    <h5 className="m-0 text-orange">Teléfono</h5>
                    <h6 className="text-light">222 831 9465</h6>
                  </Col>
                </Row>
              </Col>
              <Col className="py-2" xs={12} md={6}>
                <Row className="px-md-0 pl-3 pl-md-0">
                  <Col xs={2} md={3} className="pl-md-0">
                    <FontAwesomeIcon
                      size="3x"
                      className="text-light rounded-circle bg-black p-2 icon_cont"
                      icon={["far", "envelope"]}
                    />
                  </Col>
                  <Col className="pr-md-0 pl-md-1">
                    {" "}
                    <h5 className="m-0 text-orange">Email</h5>
                    <h6 className="text-light">info@alfaferre.mx</h6>
                  </Col>
                </Row>
              </Col>
              <Col className="py-2" xs={12} md={6}>
                <Row className="pl-3 pl-md-0">
                  <Col xs={2} md={3}>
                    <FontAwesomeIcon
                      size="3x"
                      className="text-light rounded-circle bg-black p-2 icon_cont"
                      icon={["fas", "map-marker-alt"]}
                    />
                  </Col>
                  <Col>
                    <h5 className="m-0 text-orange">Ubicaciones</h5>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3 justify-content-center">
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>Elegir el punto de venta</DropdownToggle>
                <DropdownMenu>
                  {sucursales.map(({ title, text, tel, cel, to }, i) => (
                    <DropdownItem className="mb-3 " key={i}>
                      <Row className="">
                        <h5 className="m-0 text-orange">{title}</h5>
                        <h6 className="m-0 ">{text}</h6>
                      </Row>
                      <Row className="my-2">
                        <h6 className="">{tel}</h6>
                        <h6 className="">{cel}</h6>
                        <Button
                          block
                          tag="a"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={to}
                          className="btn-secondary "
                        >
                          Cómo llegar
                        </Button>
                      </Row>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </Row>
          </Col>
          <Col xs={12} md={7} className="px-4">
            {" "}
            <ContactFormCont />
          </Col>
        </Row>
        <hr className="hrFooter" />

        <Row className=" d-flex flex-direction-column justify-content-center ">
          <Navbar className=" " expand="md">
            <Nav className="text-center" navbar>
              {legalesLinks.map(({ to, name }, i) => (
                <NavItem className="" key={i}>
                  <NavLink className="mx-1" tag={Link}>
                    <a
                      className="item_dorp"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={to}
                    >
                      {name}
                    </a>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Navbar>
        </Row>

        <Row className="d-flex flex-direction-column justify-content-center ">
          <p className="text-light">Copyright ©2020. All Rights Reserved.</p>
        </Row>
        <Row className="d-flex flex-direction-column justify-content-center pb-3">
          <a
            className=" text-white"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.n12.mx/"
          >
            Hecho por N12 Estudio
          </a>
        </Row>
      </Container>
    </>
  )
}
export default Footer
