import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from "reactstrap"
import "../../styles/generate.css"
import ScrollAnimation from "react-animate-on-scroll"
import { formatNmxn, numberFromFormatted, b } from "../../utils"

function CalcFinancing(props) {
  const { className } = props
  const [modal, setModal] = useState(false)
  const [investmentYield, setInvestmentYield] = useState({
    monthlyPerfomance: 0,
    VATperformance: 0,
    monthlySubtotal: 0,
    monthlyVAT: 0,
    monthlyIncomeTax: 0,
    monthlyDeposit: 0,
    anualDeposit: 0,
    anualGrossReturn: 0,
  })
  const toggle = () => setModal(!modal)
  const [amountText, setAmountText] = useState("")

  const getValue = ({ target: { value } }) => {
    setAmountText(b(value))
  }

  const amount = numberFromFormatted(amountText)

  const constInterest = 0.026252
  const constInteresIVA = 0.0304524
  const constRetencionISR = 0.2
  const constRetencionIVA = 0.1066667
  const constPercentage = 0.16
  const n = 12

  useEffect(() => {
    function getData() {
      let newAmount = amount
      let totalInteresInsoluto = 0
      let totalPago = 0
      const pago =
        (newAmount * constInteresIVA) / (1 - (1 + constInteresIVA) ** -n)

      for (let i = 0; i < n; i++) {
        const interesInsoluto = newAmount * constInterest
        const ivaInsoluto = interesInsoluto * constPercentage
        const abonoCapital = pago - interesInsoluto - ivaInsoluto
        newAmount = newAmount - abonoCapital
        totalInteresInsoluto = totalInteresInsoluto + interesInsoluto
        totalPago = totalPago + pago
      }

      const rendimientoMensual = totalInteresInsoluto / n // monthlyPerfomance
      const iVARendimiento = rendimientoMensual * constPercentage // VATperformance
      const subtotalMensual = rendimientoMensual + iVARendimiento // monthlySubtotal
      const retencionIVAAnual = totalInteresInsoluto * constRetencionIVA
      const retencionIVAMensual = retencionIVAAnual / n // monthlyVAT
      const retencionISRAnual = totalInteresInsoluto * constRetencionISR
      const retencionISRMensual = retencionISRAnual / n //monthlyIncomeTax
      const depositoMensual =
        subtotalMensual - (retencionIVAMensual + retencionISRMensual) //monthlyDeposit
      const totalDepositoAnual = depositoMensual * n //anualDeposit
      const rendimientoMensualBruto = (totalPago - amount) / n
      const rendimientoBruto = rendimientoMensualBruto * n //anualGrossReturn

      setInvestmentYield( (currentState)=>(
        {
        ...currentState,

        monthlyPerfomance: rendimientoMensual.toFixed(2),
        VATperformance: iVARendimiento.toFixed(2),
        monthlySubtotal: subtotalMensual.toFixed(2),
        monthlyVAT: retencionIVAMensual.toFixed(2),
        monthlyIncomeTax: retencionISRMensual.toFixed(2),
        monthlyDeposit: depositoMensual.toFixed(2),
        anualDeposit: totalDepositoAnual.toFixed(2),
        anualGrossReturn: rendimientoBruto.toFixed(2),
      }
      ))
    }
    if (amount > 0) {
      getData()
    }
  }, [amount])

  const {
    monthlyPerfomance,
    VATperformance,
    monthlySubtotal,
    monthlyVAT,
    monthlyIncomeTax,
    monthlyDeposit,
    anualDeposit,
    anualGrossReturn,
  } = investmentYield

  return (
    <Container
      fluid
      className="bg-secondary py-5 px-5 d-flex flex-column justify-content-center"
    >
      {" "}
      <Row className="align-items-center justify-content-center">
        <Col md={1}></Col>
        <Col xs={12} md={4} className="mt-4 mb-1 px-3 py-3 bg-white rounded">
          <Form onSubmit={e => e.preventDefault()}>
            <FormGroup>
              <Label className="">Monto a Invertir</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input
                  placeholder="De $10,000.00 a $5,000,000.00"
                  onChange={getValue}
                  value={amountText}
                  type="text"
                />
                <InputGroupAddon addonType="append">.00</InputGroupAddon>
              </InputGroup>
              
            </FormGroup>
          </Form>
        </Col>
        {amount >= 10000 && amount <= 5000000 ? (
          <Col
            md={4}
            className="ml-md-5 px-md-5  bg-white rounded justify-content-center mt-5"
          >
            <Table className=" d-flex table-responsive justify-content-center">
              <tbody className="">
                <tr>
                  <th scope="row" className="p-md-3">
                    Rendimiento Mensual
                  </th>

                  <td className="text-center">
                    {`$ ${formatNmxn(monthlyPerfomance)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    IVA del Rendimiento
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(VATperformance)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Subtotal Mensual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(monthlySubtotal)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Retención IVA Mensual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(monthlyVAT)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Retención ISR Mensual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(monthlyIncomeTax)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Total a depositar mensual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(monthlyDeposit)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Total a depositar anual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(anualDeposit)}`}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="p-md-3">
                    Rendimiento Bruto Anual
                  </th>
                  <td className="text-center">
                    {`$ ${formatNmxn(anualGrossReturn)}`}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        ) : null}

        <Col md={1}></Col>
      </Row>
      <Row className=" text-center justify-content-center d-block ">
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
          <Button
            className="my-3"
            target="_blank"
            rel="noopener noreferrer"
            href="#contacto"
          >
            Contáctanos
          </Button>
        </ScrollAnimation>
      </Row>
      <Row className="justify-content-center">
        <div className="">
          <a onClick={toggle} className="hover_calc_button">
          <h5 className=" text-yellow font-weight-bold aliging mb-5">
            Garantías y Riesgos
          </h5>
        </a>
        </div>
        <Modal isOpen={modal} toggle={toggle} className={className} className="modal-xl">
          <div className="">
          <ModalHeader toggle={toggle} className="text-center">
            Entiendo que toda inversión tiene un riesgo, ¿mi capital lo está?.
            Esto supone recibir beneficios y asumir algunas variables 
          </ModalHeader>
          </div>
          <ModalBody >
            <p className="multiline">
            Alfa Ferre es una empresa constituida legalmente en 2010 en México y registrada ante la Secretaría de Hacienda y Crédito Público (RFC CAAE910320HG0). Debido a su modelo de negocio no requiere de autorización de la Comisión Nacional Bancaria de Valores (CNBV) ni de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF). 
            <br/><br/>
            El principal riesgo del inversionista es el retraso en los pagos o que no se pague el monto invertido. En caso de que esto ocurra, gestionaremos la cobranza de una manera ética y profesional, y trataremos por todos los medios posibles recuperar los pagos atrasados para así evitar que el inversionista tenga una pérdida. 
            <br/><br/>
            Si bien no se puede blindar completamente una inversión, en Alfa Ferre se disminuyen esos riesgos mediante la diversificación de portafolio y la aplicación de un proceso de “due diligence” al cliente. Alfa Ferre no puede garantizar la recuperación del monto invertido o sus intereses, por eso se es extremadamente cuidadoso en el proceso de autorización. Ni el gobierno federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos que utilices en tus operaciones con Alfa Ferre, ni tampoco asumir alguna responsabilidad por las obligaciones contraídas por Alfa Ferre, o por algún cliente, frente a otro en virtud de las operaciones que sean celebradas. 
            <br/><br/>
            También es prudente mencionar que el capital de su inversión se podría ver afectado por motivos o causas de fuerza mayor que están fuera del control de la empresa, las cuales incluyen pero no se limitan a actos de autoridades, huelgas, procedimientos administrativos, paros, guerras, sabotajes, disposiciones oficiales, alteraciones públicas, movimientos telúricos, inundaciones, huracanes y manifestaciones de la naturaleza o del clima. 
            <br/><br/>
            Para minimizar el riesgo se sugiere empezar poco a poco. Conozca el modelo, observe los flujos de cobranza y, conforme su confianza crezca, invierta más dinero. Debido al modelo de negocio, no es posible invertir a un plazo menor a 12 meses. Si usted lo desea puede renovar la inversión cada año para que su dinero siga creciendo al mismo ritmo.
            </p>
          </ModalBody>
        </Modal>
      </Row>
    </Container>
  )
}

export default CalcFinancing
