import React, { Component } from "react"
// import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorInfo: null,
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo })
    // Sentry.withScope((scope) => {
    //   scope.setExtras(errorInfo);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({ eventId });
    // });
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      //render fallback UI
      return <div>Este componente tiene un error</div>
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}

export default ErrorBoundary
