export const POSSIBLE_TERM_TYPES = {
  WEEK: {
    label: " 16 Semanas",
    value: 16,
  },
  SIX: {
    label: "6 Meses",
    value: 6,
  },
  NINE: {
    label: "9 Meses",
    value: 9,
  },
  TWELVE: {
    label: "12 Meses",
    value: 12,
  },
}
export const POSSIBLE_PAYMENT_FREQUENCY_TYPES = {
  WEEK: {
    label: " Semanal",
    value: " Semanal",
  },
  FOURTEEN: {
    label: "Catorcenal",
    value: "Catorcenal",
  },
  MONTH: {
    label: "Mensual",
    value: "Mensual",
  },
}

export const VAL_CONST_CALCULATOR = {
  RATES: {
    best: 0.0171538784758236,
    second: 0.0188829055966917,
    average: 0.0205988751168799,
    bank: 0.08,
    rank1: 0.0753655952252125,
    rank2: 0.0687536367254627,
    rank3: 0.0620488679744946,
    rank4: 0.0552436775316529,
    rank5: 0.0483300169213439,
    rank6: 0.0434461553229095,
  },
  PERIODS: {
    week: -16,
    week8: -8,
    month6: -6,
    month9: -9,
    month12: -12,
  },
}
