import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  Container,
} from "reactstrap"
import logo from "../images/LogoNavb.svg"

import "../styles/navb.css"

function Navb() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const routeLinks = [
    {
      to: `https://tienda.alfaferre.mx`,
      external: true,
      name: "Tienda",
    },
    {
      to: "/remodela/",
      name: "Remodela",
    },
    {
      to: "/construye/",
      name: "Construye",
    },
    {
      to: "/invierte/",
      name: "Invierte",
    },
    {
      to: "/cripto/",
      name: "Cripto",
    },
    
    {
      to:
        "https://docs.google.com/forms/d/e/1FAIpQLSfSHJkwbGRT9SKY2pgbYIABAvYzWG9dPT63nacS41xS3xGrJw/viewform?usp=sf_link",
      external: true,
      name: "Haz Carrera Con Nosotros",
    },
    {
      to:
        "https://docs.google.com/forms/d/e/1FAIpQLSc2ZhYTN87k-8zYIqs3rG0bq2ArtaSMncO88-alcpa0UEnVTA/viewform?usp=sf_link",
      external: true,
      name: "Quiero Ser Proveedor",
    },
  ]
  const legales = [
    {
      to:
        "https://drive.google.com/file/d/1oXVFCb1shjiWOjfTWrq3-3mzG4ewwGwp/view",
      name: "Política de garantías y devoluciones",
    },
    {
      to:
        "https://drive.google.com/file/d/1XECH3kR3Dlk2Px3kRdsYQIew51DX1VkN/view",
      name: "  Política de venta",
    },
    {
      to:
        "https://drive.google.com/file/d/1B4HhDMqIcVb3mjbeatHB3lTjhg2kgmzB/view",
      name: "Aviso de privacidad",
    },
  ]
  return (
    <Container className="">
      <Navbar className="bg-black borderNav  m-0 p-0" fixed="top" expand="lg">
        <NavbarBrand href="/">
          <img
            src={logo}
            alt="main logo"
            className="img-fluid logo ml-4 ml-md-5 p-0"
          />
        </NavbarBrand>
        <NavbarToggler className="  navbar-dark" onClick={toggle} />
        <Collapse className="pl-3 navcoloritem" isOpen={isOpen} navbar>
          <Nav className="ml-auto text-end" navbar>
            {routeLinks.slice(0, 6).map(({ to, name, external }, i) => (
              <NavItem className="bg-nav" key={i}>
                <NavLink
                  className="mx-1 navcoloritem"
                  target={external ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  tag={external ? "a" : Link}
                  to={to}
                  href={to}
                >
                  {name}
                </NavLink>
              </NavItem>
            ))}
            <UncontrolledDropdown className="bg-nav" nav inNavbar>
              <DropdownToggle className="mx-1 navcoloritem" nav caret>
                Legales
              </DropdownToggle>
              <DropdownMenu right className="bg_Drop">
                {legales.map(({ to, name }, i) => (
                  <DropdownItem
                    className="item_drop"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={to}
                    tag={Link}
                  >
                    {name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            {routeLinks.slice(6).map(({ to, name }, i) => (
              <NavItem className="bg-nav" key={i}>
                <NavLink className="mx-1 navcoloritem" tag={Link} to={to}>
                  {name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    </Container>
  )
}

export default Navb
