import React, { useState } from "react"
import {
  Container,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  Input,
  Form,
  FormGroup,
  Label,
  Table,
} from "reactstrap"
import { formatNmxn, numberFromFormatted, b } from "../../utils"
import { VAL_CONST_CALCULATOR } from "../../constants/calculator_financing.js"

import "../../styles/calculator_financing.css"

const CalculatorFinancing = () => {
  const [amountText, setAmountText] = useState("")

  const getValue = ({ target: { value } }) => {
    setAmountText(b(value))
  }

  //console.log(numberFromFormatted(amountText))
  const amount = numberFromFormatted(amountText)
  console.log(formatNmxn(3000))
  //const amount = parseInt(amountText, 10)
  const rate =
    amount <= 32499
      ? VAL_CONST_CALCULATOR.RATES.best
      : amount >= 32500 && amount <= 62499
      ? VAL_CONST_CALCULATOR.RATES.rank1
      : amount >= 62500 && amount <= 92499
      ? VAL_CONST_CALCULATOR.RATES.rank2
      : amount >= 92500 && amount <= 122499
      ? VAL_CONST_CALCULATOR.RATES.rank3
      : amount >= 122500 && amount <= 152499
      ? VAL_CONST_CALCULATOR.RATES.rank4
      : amount >= 152500 && amount <= 182499
      ? VAL_CONST_CALCULATOR.RATES.rank5
      : amount >= 182500 && amount <= 210000
      ? VAL_CONST_CALCULATOR.RATES.rank6
      : null
  // console.log(rate)
  // console.log(numberFromFormatted("2,500.00"))
  const weekBestRate = Math.ceil(
    (amount * rate) / (1 - (1 + rate) ** VAL_CONST_CALCULATOR.PERIODS.week)
  ).toFixed(2)
  const weekAverageRate = Math.ceil(
    (amount * VAL_CONST_CALCULATOR.RATES.average) /
      (1 -
        (1 + VAL_CONST_CALCULATOR.RATES.average) **
          VAL_CONST_CALCULATOR.PERIODS.week)
  ).toFixed(2)
  const weekSecondRate = Math.ceil(
    (amount * VAL_CONST_CALCULATOR.RATES.second) /
      (1 -
        (1 + VAL_CONST_CALCULATOR.RATES.second) **
          VAL_CONST_CALCULATOR.PERIODS.week)
  ).toFixed(2)
  const week8BestRate = Math.ceil(weekBestRate * 2).toFixed(2)
  const week8AverageRate = Math.ceil(weekAverageRate * 2).toFixed(2)
  const week8SecondRate = Math.ceil(weekSecondRate * 2).toFixed(2)
  const month6BestRate = Math.ceil(
    (amount * rate) / (1 - (1 + rate) ** VAL_CONST_CALCULATOR.PERIODS.month6)
  )

  const month9BestRate = Math.ceil(
    (amount * rate) / (1 - (1 + rate) ** VAL_CONST_CALCULATOR.PERIODS.month9)
  ).toFixed(2)

  const month12BestRate = Math.ceil(
    (amount * rate) / (1 - (1 + rate) ** VAL_CONST_CALCULATOR.PERIODS.month12)
  ).toFixed(2)

  return (
    <Container
      fluid
      className="bg_calcu  d-flex flex-column justify-content-center p-5"
    >
      {" "}
      <Row className="align-items-center">
        <Col xs={12} lg={4} className="ml-md-3 mb-1 px-3 py-3 bg-white rounded">
          <Form onSubmit={e => e.preventDefault()}>
            <FormGroup>
              <Label className="">¿Cuánto necesitas?</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input
                  placeholder="De $10,000.00 a $210,000.00"
                  onChange={getValue}
                  value={amountText}
                  type="text"
                />
                <InputGroupAddon addonType="append">.00</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
        {amountText === "" || amount <= 9999 || amount >= 210001 ? (
          <Col md={7} className="ml-md-2 py-3 ">
            <h1 className="text-center">
              {" "}
              <font color="#E4D72B">
                ¡Deja de postergar esa remodelación! Cotiza ahora tu
                financiamiento y empieza cuanto antes.
              </font>
            </h1>
          </Col>
        ) : null}
        {amount >= 10000 && amount <= 32499 ? (
          <>
            <Col
              md={7}
              className="ml-md-5 px-md-3 py-3 bg-white rounded d-none d-sm-block"
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th>Solicitud</th>
                    <th>Primera vez</th>
                    <th>Segunda vez</th>
                    <th className=" text-success font-weight-bold bg_succesTable">
                      Tercera vez
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">16 Pagos semanales</th>
                    <td className="">{`$ ${formatNmxn(weekAverageRate)}`}</td>
                    <td> {`$ ${formatNmxn(weekSecondRate)}`} </td>
                    <td className=" text-success font-weight-bold bg_succesTable">
                      {`$ ${formatNmxn(weekBestRate)}`}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">8 Pagos semanales</th>
                    <td className="">{`$ ${formatNmxn(week8AverageRate)}`}</td>
                    <td>{`$ ${formatNmxn(week8SecondRate)}`} </td>
                    <td className=" text-success font-weight-bold bg_succesTable">
                      {`$ ${formatNmxn(week8BestRate)}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col
              md={8}
              className="ml-md-5 px-md-5 py-3 bg-white rounded d-md-none "
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th>Solicitud</th>
                    <th>16 Pagos semanales</th>
                    <th>8 Pagos semanales</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Primera vez</th>
                    <td className=""> {`$ ${formatNmxn(weekAverageRate)}`}</td>
                    <td className=""> {`$ ${formatNmxn(week8AverageRate)}`}</td>
                  </tr>
                  <tr>
                    <th scope="row">Segunda vez</th>
                    <td> {`$ ${formatNmxn(weekSecondRate)}`}</td>
                    <td> {`$ ${formatNmxn(week8SecondRate)}`}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className=" text-success font-weight-bold bg_succesTable"
                    >
                      Tercera vez
                    </th>
                    <td className=" text-success font-weight-bold bg_succesTable">
                      {`$ ${formatNmxn(weekBestRate)}`}
                    </td>
                    <td className=" text-success font-weight-bold bg_succesTable">
                      {`$ ${formatNmxn(week8BestRate)}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </>
        ) : amount >= 32500 && amount <= 210000 ? (
          <>
            <Col
              md={7}
              className="ml-md-5 px-md-3 py-3 bg-white rounded d-none d-sm-block"
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th>Numero de pagos </th>
                    <th>6 Pagos mensuales</th>
                    <th>9 Pagos mensuales</th>
                    <th>12 Pagos mensuales</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Monto</th>
                    <td className=""> {`$ ${formatNmxn(month6BestRate)}`}</td>
                    <td> {`$ ${formatNmxn(month9BestRate)}`}</td>
                    <td className=" "> {`$ ${formatNmxn(month12BestRate)}`}</td>
                  </tr>
                  <tr>
                    <th scope="row">Total</th>
                    <td className="">
                      {" "}
                      {`$ ${formatNmxn(month6BestRate * 6)}`}
                    </td>
                    <td> {`$ ${formatNmxn(month9BestRate * 9)}`}</td>
                    <td className=" ">
                      {`$ ${formatNmxn(month12BestRate * 12)}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col
              md={8}
              className="ml-md-5 px-md-5 py-3 bg-white rounded d-md-none pr-0 pl-1"
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th className="pr-0 pl-0">Numero de pagos</th>
                    <th>Monto</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="pr-0 pl-0">
                      6 Pagos mensuales
                    </th>
                    <td className="p-1">
                      {" "}
                      {`$ ${formatNmxn(month6BestRate)}`}
                    </td>
                    <td className="p-1">
                      {" "}
                      {`$ ${formatNmxn(month6BestRate * 6)}`}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="pr-0 pl-0">
                      9 Pagos mensuales
                    </th>
                    <td className="p-1">
                      {" "}
                      {`$ ${formatNmxn(month9BestRate)}`}
                    </td>
                    <td className="p-1">
                      {" "}
                      {`$ ${formatNmxn(month9BestRate * 9)}`}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="pr-0 pl-0">
                      12 Pagos mensuales
                    </th>
                    <td className="p-1">
                      {" "}
                      {`$ ${formatNmxn(month12BestRate)}`}
                    </td>
                    <td className="p-1">
                      {`$ ${formatNmxn(month12BestRate * 12)}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </>
        ) : null}
      </Row>
    </Container>
  )
}
export default CalculatorFinancing
