import React from "react"

import wh from "../../images/landing/wh.png"
function WhastappIcon() {
  return (
    <div
      className="whatsapp_icon"
      style={{
        position: "fixed",
        right: "12px",
        bottom: "15px",
        zIndex: "1200",
      }}
    >
      <a
        className="text-light"
        target="_blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send?phone=522228319465&text=%C2%A1Hola%20Alfa%20Ferre!%20Tengo%20una%20duda%20con..."
      >
        <img src={wh} alt="whatsapp" />
        {/* <FontAwesomeIcon
          style={{ width: "60px" }}
          size="4x"
          icon={["fab", "whatsapp"]}
        /> */}
      </a>
    </div>
  )
}

export default WhastappIcon
