import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ContactForm({
  isFetching,
  startPostContact,
  resetContact,
  message: globalMessage,
}) {
  const [showMessage, setShowMessage] = useState(false)
  const [formVals, setFormVals] = useState({
    fullname: "",
    email: "",
    phone: "",
    interest: "",
    message: "",
  })
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value,
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const data = { ...formVals }
    // console.log({ data })
    const formData = new FormData()
    for (let key in data) {
      data[key] && formData.append(key, data[key])
    }
    startPostContact(formData)
  }
  useEffect(() => {
    if (globalMessage) {
      toggleMessage()
      setFormVals({
        fullname: "",
        email: "",
        phone: "",
        interest: "",
        message: "",
      })
    }
  }, [globalMessage])

  const toggleMessage = () => {
    if (showMessage) {
      resetContact()
    }
    setShowMessage(!showMessage)
  }

  const { fullname, email, phone, interest, message } = formVals

  return (
    <>
      {isFetching ? (
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <FontAwesomeIcon
                size="3x"
                className="text-white"
                spin
                icon="spinner"
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input
                  value={fullname}
                  onChange={getValue}
                  required
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Nombre completo"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  value={email}
                  onChange={getValue}
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="usuario@correo.com"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input
                  value={phone}
                  onChange={getValue}
                  required
                  name="phone"
                  maxLength="10"
                  placeholder="Teléfono"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    value={interest}
                    onChange={getValue}
                    type="select"
                    name="interest"
                    id="interest"
                  >
                    <option>Selecciona una opción</option>

                    <option value="remodela">Remodela</option>
                    <option value="construye">Construye</option>
                    <option value="invierte">Invierte</option>
                    <option value="cripto">Cripto</option>
                    <option>Otro</option>
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm={12}>
              <Input
                className="cont_20"
                value={message}
                onChange={getValue}
                required
                type="textarea"
                name="message"
                id="message"
                placeholder="Mensaje"
              />
            </Col>
          </FormGroup>
          <Button type="submit" className="btn-block btn-primary">
            Enviar
          </Button>
        </Form>
      )}
      {globalMessage && (
        <Alert
          isOpen={showMessage}
          toggle={toggleMessage}
          className="mt-1"
          color="primary"
        >
          El mensaje ha sido enviado con éxito.
        </Alert>
      )}
    </>
  )
}

export default ContactForm
